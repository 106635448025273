import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230628-Rate-Sheet.pdf";
const date = "28.06.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Wednesday 28 June 2023 we will be making the following changes to our range:</p>
    <p><strong>Summary of changes</strong></p>
    <p><strong>UK Residential Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Tracker Fee Saver at 95% LTV has decreased</li>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
      <li>10 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
    </ul>
    <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
      <li>10 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
    </ul>
    <p><strong>UK Residential First Time Buyer Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p><strong>UK Residential Home Mover Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p><strong>UK Residential Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70%, 75% and 80% LTV have increased</li>
      <li>10 Year Fixed Standard at 60%, 70%, 75% and 80% LTV have increased</li>
    </ul>
    <p><strong>International Residential Purchase Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Fee Saver at 70%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Standard at 70%, 65% and 75% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70% and 75% TLV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>10 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
    </ul>
    <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p><strong>UK Buy to Let Purchase Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p><strong>UK Buy to Let Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p>There are no changes to any other interest rates at this time.</p>
  </NewsArticleLayout>
);

export default Article;
